import React from 'react'
import Header from "../../components/page-objects/Header";
import Layout from "../../components/page-objects/Layout";
import { kebabCase } from 'lodash'
import { Link, graphql } from 'gatsby'
import Spacer from "../../components/ui-objects/Spacer";

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
  },
}) => (
  <Layout>
    <Header headerHeight="250" backgroundPositie="0" />
    <section className="tags">
      <div>
        <div>
          <div>
            <h3>All tags</h3>
            <Spacer size="l" />
            <ul>
              {group.map((tag) => (
                <li key={tag.fieldValue}>
                  <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                    {tag.fieldValue} ({tag.totalCount})
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
